import Vue from 'vue';
import App from './App.vue';
import modernizr from 'modernizr'; // eslint-disable-line no-unused-vars
import 'bootstrap/scss/bootstrap.scss';
import './assets/scss/main.scss';

Vue.config.productionTip = false;

new Vue({
  render: h => h(App),
}).$mount('#app')
